import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IthunkAPI, iEmployee } from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import {
  IEmployeeQueryParam,
  IEmployeeResponse,
} from "../../types/api/EmployeeApis";

export const fetchEmployeeList = createAsyncThunk<
  IEmployeeResponse,
  Partial<IEmployeeQueryParam>,
  IthunkAPI
>("User/fetchEmployeeList", async (params, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  //   const token = await dispatch(getToken());
  const token = BearerToken();
  try {
    let pageNo = 1;
    let allEmployees: iEmployee[] = [];
    let hasMorePages = true;
    while (hasMorePages) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employees/list`,
        {
          params: { ...params, pageNo },
          headers: {
            //   Authorization: `Bearer ${token}`,
            Authorization: token,
          },
        }
      );
      const { data } = response.data;
      hasMorePages =
        response.data.meta.page.currentPage < response.data.meta.page.lastPage;
      pageNo++;
      allEmployees = allEmployees.concat(data);
    }
    return {
      data: allEmployees,
    };
  } catch (err: any) {
    if (axios.isCancel(err)) {
      // Handle cancellation separately
      return rejectWithValue({ canceled: true });
    } else {
      // Handle other errors
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});

export const updateEmployee = createAsyncThunk<
  iEmployee,
  iEmployee,
  { rejectValue: string }
>("User/updateEmployee", async (employee, thunkAPI) => {
  const token = BearerToken();
  try {
    // Flatten the nested shift_start_address object to separate properties
    let updatedEmployee;
    if (
      employee.shiftStartAddress &&
      typeof employee.shiftStartAddress === "object"
    ) {
      updatedEmployee = {
        colorHexCode: employee.colorHexCode,
        shift_start_address_city: employee.shiftStartAddress.city,
        shift_start_address_state: employee.shiftStartAddress.state,
        shift_start_address_country: employee.shiftStartAddress.country,
        shift_start_address_street: employee.shiftStartAddress.street,
        shift_start_address_zip_code: employee.shiftStartAddress.zipCode,
        shift_start_address_latitude: employee.shiftStartAddress.latitude,
        shift_start_address_longitude: employee.shiftStartAddress.longitude,
      };
      // delete updatedEmployee.shift_start_address;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/employees/${employee.id}`,
      updatedEmployee,
      { headers: { Authorization: token } }
    );
    return response.data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(
      err?.response?.data?.errorDescription || "Update failed"
    );
  }
});
