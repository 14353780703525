// Employeeslice.ts
import { createSlice } from "@reduxjs/toolkit";
import { iEmployee } from "../../types";
import { fetchEmployeeList, updateEmployee } from "../thunk/employeeApi";

interface IEmployeeState {
  EmployeeList: iEmployee[];
  EmployeeListLoading: boolean;
  EmployeeListError: string;
  EmployeePostLoading: boolean;
  EmployeePostError: string;
}

const initialState: IEmployeeState = {
  EmployeeList: [],
  EmployeeListError: "",
  EmployeeListLoading: false,
  EmployeePostError: "",
  EmployeePostLoading: false,
};

const EmployeeSlice = createSlice({
  name: "Employee",
  initialState,
  reducers: {},
  extraReducers: (Builder) => {
    //List reducer
    Builder.addCase(fetchEmployeeList.pending, (state) => {
      return {
        ...state,
        EmployeeListLoading: true,
      };
    });
    Builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
      return {
        ...state,
        EmployeeListLoading: false,
        EmployeeList: action.payload.data,
        EmployeeListError: "",
      };
    });
    Builder.addCase(fetchEmployeeList.rejected, (state, action) => {
      return {
        ...state,
        EmployeeListLoading: false,
        EmployeeListError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
    Builder.addCase(updateEmployee.pending, (state) => {
      return {
        ...state,
        EmployeePostLoading: true,
      };
    });
    Builder.addCase(updateEmployee.fulfilled, (state) => {
      return {
        ...state,
        EmployeePostLoading: false,
        EmployeePostError: "",
      };
    });
    Builder.addCase(updateEmployee.rejected, (state, action) => {
      return {
        ...state,
        EmployeePostLoading: false,
        EmployeePostError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
  },
});

export const {} = EmployeeSlice.actions;

export default EmployeeSlice.reducer;
