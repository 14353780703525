import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IthunkAPI } from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import { ISubscriberResponse } from "../../types/api/SubscriberApis";
import { getSubscriberId } from "../../utils/helper";

export const fetchSubscriberDetail = createAsyncThunk<
  ISubscriberResponse,
  void
>("fetchSubscriberDetail", async (_, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  //   const token = await dispatch(getToken());
  const token = BearerToken();
  try {
    const subscriberId = getSubscriberId();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/subscribers/${subscriberId}`,
      {
        headers: {
          //   Authorization: `Bearer ${token}`,
          Authorization: token,
        },
      }
    );
    return {
      data: response.data,
    };
  } catch (err: any) {
    if (axios.isCancel(err)) {
      // Handle cancellation separately
      return rejectWithValue({ canceled: true });
    } else {
      // Handle other errors
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});
