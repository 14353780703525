// Subscriberslice.ts
import { createSlice } from "@reduxjs/toolkit";
import { ISubscriber } from "../../types";
import { fetchSubscriberDetail } from "../thunk/subscriberApi";

interface ISubscriberState {
  SubscriberDetails: ISubscriber;
  SubscriberDetailLoading: boolean;
  SubscriberDetailError: string;
}

const initialState: ISubscriberState = {
  SubscriberDetails: {
    officeAddress: {
      street: "",
      country: "",
      city: "",
      state: "",
      zipCode: "",
    },
  },
  SubscriberDetailError: "",
  SubscriberDetailLoading: false,
};

const SubscriberSlice = createSlice({
  name: "Subscriber",
  initialState,
  reducers: {},
  extraReducers: (Builder) => {
    //Detail reducer
    Builder.addCase(fetchSubscriberDetail.pending, (state) => {
      return {
        ...state,
        SubscriberDetailLoading: true,
      };
    });
    Builder.addCase(fetchSubscriberDetail.fulfilled, (state, action) => {
      return {
        ...state,
        SubscriberDetailLoading: false,
        SubscriberDetails: action.payload.data,
        SubscriberDetailError: "",
      };
    });
    Builder.addCase(fetchSubscriberDetail.rejected, (state, action) => {
      return {
        ...state,
        SubscriberDetailLoading: false,
        SubscriberDetailError: "Unable to Fetch Subscribers details",
      };
    });
  },
});

export const {} = SubscriberSlice.actions;

export default SubscriberSlice.reducer;
